export default [
  {
    name: "parent1",
    label: "Parent #1",
    questions: [
      {
        name: "firstName1",
        label: "First Name",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "lastName1",
        label: "Last Name",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "address1",
        label: "Address",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "city1",
        label: "City",
        type: "input",
        required: true,
      },
      {
        name: "state1",
        label: "State",
        type: "state",
        required: true,
      },
      {
        name: "zip1",
        label: "Zip Code",
        type: "input",
        required: true,
      },
      {
        name: "isChildcareaddress1",
        label: "Is this the job location?",
        type: "radio",
        options: ["yes","no"],
        checked: "speenooch",
      },
      {
        name: "Phone1",
        label: "Phone #",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "email1",
        label: "E-mail",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "profession1",
        label: "What is your profession?",
        type: "input",
        subType: "text",
        required: false,
        placeholder: "What do you do for work?",
      },
      {
        name: "placeofemployment1",
        label: "Do you work from home?",
        type: "input",
        subType: "text",
        required: false,
        placeholder: "Is this temporary due to Covid-19?",
      },
    ],
  },
  {
    name: "parent2",
    label: "Parent #2",
    questions: [
      {
        name: "firstName2",
        label: "First Name",
        type: "input",
        subType: "text",
        required: false,
        placeholder: "",
      },
      {
        name: "lastName2",
        label: "Last Name",
        type: "input",
        subType: "text",
        required: false,
        placeholder: "",
      },
      {
        name: "address2",
        label: "Address (if different)",
        type: "input",
        subType: "text",
        required: false,
        placeholder: "",
      },
      {
        name: "location2",
        label: "City,State,Zip",
        type: "input",
        subType: "text",
        required: false,
        placeholder: "",
      },
      {
        name: "isChildcareaddress2",
        label: "Is this also the job location?",
        type: "radio",
        options: ["yes","no"],
        checked: "no",
      },
      {
        name: "Phone2",
        label: "Phone #",
        type: "input",
        subType: "text",
        required: false,
        placeholder: "",
      },
      {
        name: "email2",
        label: "E-mail",
        type: "input",
        subType: "text",
        required: false,
        placeholder: "",
      },
      {
        name: "profession2",
        label: "What is your profession?",
        type: "input",
        subType: "text",
        required: false,
        placeholder: "What do you do for work?",
      },
      {
        name: "placeofemployment2",
        label: "Do you work from home?",
        type: "input",
        subType: "text",
        required: false,
        placeholder: "Is this temporary due to Covid-19?",
      },
    ],
  },
  {
    name: "familydetails",
    label: "Children & Pets",
    questions: [
      {
        name: "children1",
        label: "Child #1",
        type: "textArea",
        required: true,
        placeholder:
          "Full Name,Ages (use months if under 3 years),interests,special needs,etc...",
        rows: 10,
      },
      {
        name: "children2",
        label: "Child #2",
        type: "textArea",
        required: false,
        placeholder:
          "Full Name,Ages (use months if under 3 years),interests,special needs,etc...",
        rows: 10,
      },
      {
        name: "children3",
        label: "Child #3",
        type: "textArea",
        required: false,
        placeholder:
          "Full Name,Ages (use months if under 3 years),interests,special needs,etc...",
        rows: 10,
      },
      {
        name: "children4",
        label: "Child #4",
        type: "textArea",
        required: false,
        placeholder:
          "Full Name,Ages (use months if under 3 years),interests,special needs,etc...",
        rows: 10,
      },
      {
        name: "additional1?",
        label:
          "Do you plan on adding any additional children in the next 12 months?",
        type: "radio",
        options: ["yes","no"],
        checked: "no",
      },
      {
        name: "explainadditional1",
        label: "If Yes,please explain:",
        type: "textArea",
        required: false,
        placeholder: "",
        rows: 10,
      },
      {
        name: "pets1",
        label: "Please list and give a brief description of each of your pets:",
        type: "textArea",
        required: false,
        placeholder: "",
        rows: 10,
      },
      {
        name: "employerhistory",
        label: "Employer History",
        questions: [
          {
            name: "prioremployer1",
            label:
              "Have you hired a Nanny in the past? If so,please describe your experience",
            type: "textArea",
            required: true,
            placeholder: "",
            rows: 10,
          },
          {
            name: "prioremployer2",
            label:
              "Please select all that apply in regard to your past nanny hire(s):",
            type: "multiselect",
            options: [
              "Used An Agency",
              "W-2 Employee",
              "1099 Employee",
              "They should have been an employee?",
            ],
          },
          {
            name: "prioremployer3",
            label: "Why do you feel you would be a great Nanny Employer?",
            type: "textArea",
            required: true,
            placeholder: "",
            rows: 10,
          },
          {
            name: "prioremployer4",
            label:
              "Please select any additional benefits you plan to provide to your Nanny:",
            type: "multiselect",
            options: [
              "Health Insurance",
              "Retirement Options",
              "Provide Vehicle",
              "Live-In Option",
              "Gas Compensation",
              "Education",
              "Other",
            ],
          },
          {
            name: "EIN",
            label: "Do you have an EIN (employer identification number)?",
            type: "radio",
            options: ["yes","no"],
            checked: "no",
          },
          {
            name: "additionalinfo",
            label:
              "Is there anything you feel My Boulder Nanny,or your Nanny will need to know about your family?",
            type: "textArea",
            required: true,
            placeholder: "",
            rows: 10,
          },
          {
            name: "additionalinfo1",
            label:
              "What social distancing measures are your family taking?",
            type: "textArea",
            required: true,
            placeholder: "Are you,or anyone you are in contact with immuno-compromised?",
            rows: 10,
          },
          {
            name: "reference",
            label:
              "Do you have any past/current Nannies that would act as references for your family? If so,list their names and numbers below (up to 3):",
            type: "textArea",
            required: true,
            placeholder: "",
            rows: 10,
          },
        ],
      },
    ],
  },
  {
    name: "currentjob",
    label: "Current Nanny Requirements",
    questions: [
      {
        name: "currentemployer1",
        label: "What TERM commitment are you looking for?",
        type: "multiselect",
        options: [
          "Full Time Permanent (>30 hours,>12 wks)",
          "Part Time Permanent (10-30 hrs,>12 wks)",
          "Full Time Temp (>30 hrs,2-12wks)",
          "Part Time Temp (10-30 hrs,2-12weks)",
        ],
      },
      {
        name: "livingSituation",
        label: "What living situation(s) are you willing to consider?",
        type: "multiselect",
        options: [
          "Live-In Nanny",
          "Nanny resides in their own home"
        ],
      },
      {
        name: "livingSituation1",
        label: "Are you willing to hire a nanny who has their own child? Some families are considering this option for socialization purposes duing Covid-19",
        type: "multiselect",
        options: [
          "No",
          "Yes, and they are welcome in our home",
          "Yes, but only if they do not bring them along"
        ],
      },
      {
        name: "currentemployer2",
        label: "What other Services are you hoping your Nanny can provide?",
        type: "multiselect",
        options: [
          "Babysitter",
          "Overnight Care",
          "Housekeeping",
          "Pet Care",
          "Household Manager Duties",
          "Homeschooling",
          "Tutoring",
          "Other",
        ],
      },
      {
        name: "currentemployer3",
        label: "What skills/certifications does your ideal Nanny have?",
        type: "multiselect",
        options: [
          "Teacher",
          "Infant Specialist",
          "House Manager",
          "Nurse",
          "Lactation Consultant",
          "Other",
        ],
      },
      {
        name: "currentemployer4",
        label: "What are acceptable age range(s) of your Nanny?",
        type: "multiselect",
        options: [
          "16-17",
          "18-19",
          "20-21",
          "22-25",
          "25-30",
          "31-40",
          "40-50",
          "50+",
        ],
      },
      {
        name: "currentemployer5",
        label:
          "What hourly pay range are you comfortable with,select all that apply:",
        type: "multiselect",
        options: [
          "$12-15/hour",
          "$16-20/hour",
          "$21-25/hour",
          "$26-30/hour",
          "$30+/hour",
        ],
      },
      {
        name: "currentjob1",
        label: "Please write the nannies Job Description:",
        type: "textArea",
        required: true,
        placeholder: "We will share this with potential candidates",
        rows: 10,
      },
      {
        name: "currentjob2",
        label: "When does this job start?",
        type: "textArea",
        required: true,
        placeholder: "We will consider this date in our search",
        rows: 10,
      },
      {
        name: "currentjob3",
        label: "Is the start date flexible?",
        type: "textArea",
        required: true,
        placeholder:
          "Flexibility & a little patience may help you find your ideal fit ",
        rows: 10,
      },
      {
        name: "currentjob4",
        label:
          "Is there anything you would like to convey to My Boulder Nanny that you do not want in your Job Listing?",
        type: "textArea",
        required: true,
        placeholder: "Be as detailed or as brief as you like:",
        rows: 10,
      },
      {
        name: "hoursanddays",
        label: "What days will you need care?",
        type: "multiselect",
        options: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      },
      {
        name: "currentjob5",
        label:
          "Please list the hours you will NEED for each of the above selected days",
        type: "textArea",
        required: true,
        placeholder: "Please be as clear as possible",
        rows: 10,
      },
      {
        name: "currentjob6",
        label:
          "Please list any additional hours or responsibilities that would be NICE to have",
        type: "textArea",
        required: false,
        placeholder: "",
        rows: 10,
      },
      {
        name: "nannymusthaves1",
        label: "Please describe your absolute perfect nanny:",
        type: "textArea",
        required: true,
        placeholder: "",
        rows: 10,
      },
      {
        name: "nannymusthaves2",
        label:
          "From that description of your perfect nanny,what are the MUST haves,NICE to haves:",
        type: "textArea",
        required: false,
        placeholder: "",
        rows: 10,
      },
      {
        name: "nannymusthaves3",
        label:
          "Please list any deal breakers (i.e. tattoos,personality traits,etc.):",
        type: "textArea",
        required: true,
        placeholder: "",
        rows: 10,
      },
      {
        name: "nannymusthaves4",
        label:
          "Are you willing to interview/hire a Male nanny (aka a Manny?):",
        type: "textArea",
        required: true,
        placeholder: "",
        rows: 10,
      },
    ],
  },
  {
    name: "supportinDocuments",
    label: "Confirmation & Documents",
    questions: [
      {
        name: "agreements",
        label: "Please select all NEXT STEPS that you understand and agree to:",
        type: "multiselect",
        options: [
          "Application Fee $195 (non refundable)",
          "Virtual Meeting with us to discuss your application",
          "Sign Family/Agency Contract",
          "Search Fee $225 ",
          "Search and Selection of Candidates that Fit Your Criteria",
          "Virtual and In Person Interviews",
          "Job Offer/Acceptance",
          "Placement Dues",
          "Nanny/Family Contract",
          "Nanny Begins Work :)",
        ],
      },
    ],
  },
];

import React from "react";
import Layout from "@components/layout/layout";
import formQuestions from "@data/questions/familyFormQuestions";
import Stepper from "@components/register/registerPayStepper";
import queryString from 'query-string';
import "@styles/styles.scss";

export default (props) => {
  const formOpts = {
    userPhotoLabel: "Please provide a current photo of your family.",
    submitButtonLabel: "Submit and Proceed to Payment",
  };
  const formType = "Family";
  const formTitle = "Family Registration Form";

  const qs = queryString.parse(props.location.search);
  let currentStepQS;
  if (typeof qs.step !== 'undefined') {
    try {
      currentStepQS = JSON.parse(qs.step);
    } catch (error) {
      
    }
  }

  return (
    <Layout pageTitle="Apply">
      <div style={{ height: "15vh" }}></div>
      <Stepper
        formQuestions={formQuestions}
        formTitle={formTitle}
        formType={formType}
        formOpts={formOpts}
        currentStepQS={currentStepQS}
      />
    </Layout>
  );
};

// import React from "react";
// // import "../styles/styles.scss";

// export default () => {
//   return (
//       <div className="container">
//         <div className="columns">
//           <div className="column"></div>
//           <div className="column is-6">
//             <div className="box">
//               <div className="container">
//                 <div className="subtitle has-text-centered">
//                 We are at full capacity and not accepting new applications at this time please email myboulderbabysitter@gmail.com to be added to our wait list or with any questions.
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="column"></div>
//         </div>
//       </div>
//   );
// };
// import React from "react";
// import Layout from "@components/layout/layout";
// import image from "@images/logos/mbb-logo-1.png";
// import "@styles/styles.scss";

// export default () => {
//   return (
//     <Layout pageTitle="Apply">
//       <div style={{ height: "15vh" }}></div>
//       <div className="container">
//         <div className="columns">
//           <div className="column"></div>
//           <div className="column is-12">
//             <div className="box">
//               <div className="container">
//                 <div className="title has-text-centered">We are currently not accepting new family applications.</div>
//                 <div className="section">
//                   <div className="columns is-multiline">
//                     <div className="container has-text-centered">
//                       Please email myboulderbabysitter@gmail.com if you would like to be added to waitlist.  Thanks!
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="column"></div>
//         </div>
//       </div>
//     </Layout>
//   );
// };
